<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
      >
        {{ $t("app.choose_user") }}
      </h4>
      <div class="col-md-12 text-center" style="padding: 0">
        <span
          v-if="userLoading"
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        >
        </span>
        <select
          v-if="!userLoading"
          id="users"
          class="form-control"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          v-model="row.user_id"
          v-on:change="onInputChange"
        >
          <option v-for="(row, idx) in users" :key="idx" :value="row.id">
            {{ row.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "CardUser",
  props: ["user_id"],
  data() {
    return {
      auth: {
        access_token: "",
      },
      row: {
        user_id: this.$props.user_id,
      },
      users: [],
      userLoading: true,
    };
  },
  mounted() {},
  created() {
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    this.fetchData();
  },
  methods: {
    onInputChange() {
      const data = {
        input: this.row.user_id,
      };
      this.$emit("userChange", data);
    },

    fetchData() {
      this.userLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/users",
        method: "GET",
        data: {},
        params: {
          status: "active",
          role: "lecturer",
          paginate: 1000,
        },
      };
      this.axios(options)
        .then((res) => {
          this.userLoading = false;
          this.users = res.data.data.rows;
        })
        .catch((err) => {
          this.userLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
  },
};
</script>
